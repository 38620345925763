import {
  Component,
  HostListener,
  Inject,
  PLATFORM_ID,
  TransferState,
  afterNextRender,
} from '@angular/core';
import {
  Subject,
  Subscription,
  debounceTime,
  first,
  firstValueFrom,
} from 'rxjs';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';

import { ProductService } from '@/app/shared/services/product.service';
import { CommonService } from '@/app/shared/services/common.service';

import { Breadcrumb } from '@/app//shared/interface/breadcrumb';
import { Cart, CartItem } from '@/app/shared/interface/cart.interface';
import { Paginate } from '@/app/shared/interface/pagination.interface';
import {
  PaginationModel,
  Product,
  ProductModel,
  ProductsPaginationModel,
  RetriveUserModel,
} from '@/app/shared/interface/product.interface';
import { QueryStringService } from '@/app/shared/services/querystring.service';
import {
  CatetoryQueryString,
  PageQueryString,
  SearchUserQueryString,
  LabelQueryString
} from '@/app/shared/filter';
import { FlashSaleService } from '@/app/shared/services/flashsale.service';
import { WishlistService } from '@/app/shared/services/wishlist.service';
import { ToastrService } from 'ngx-toastr';
import { AccountService } from '@/app/shared/services/account.service';
import { Tag } from '@/app/shared/interface/tag.interface';
import { isPlatformBrowser } from '@angular/common';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { EventEmitter, Input, Output, ViewChild } from '@angular/core';
import {
  AuthService,
  IS_ACCOUNT_ACTIVE,
  IS_AUTHENTICATED,
  TOKEN,
  USERNAME,
} from '@/app/shared/services/auth.service';

declare var $: any;

@Component({
  selector: 'quick-order',
  templateUrl: './quick-order.component.html',
  styleUrls: ['./quick-order.component.scss'],
})
export class QuickOrderComponent {
  @Output() productLabel: EventEmitter<any> = new EventEmitter<any>();

  public breadcrumb: Breadcrumb = {
    title: 'Đặt Hàng Nhanh',
    items: [{ label: 'Đặt Hàng Nhanh', active: true }],
  };
  public skeletonLoader: boolean = false;
  public loaderStatus: boolean = false;
  public skeletonItems = Array.from({ length: 40 }, (_, index) => index);

  public items: any[] = [];
  public itemsExtend: any[] = [];
  public cartTotalItems: number = 0;
  public sumTotalPrice: number = 0;
  public inputText: string;
  public bodyExtractSearch = {
    limit: 20,
    searchTerm: '',
  };

  public cart: Cart;
  public cartItem: CartItem;

  public pagination: Paginate;
  _currentPage: number = 1;
  _paginateRange: number = 7;
  bodyProduct = {
    limit: 20,
    offset: 0,
  };

  public filters = [
    {
      name: 'Tất cả',
      active: true,
      value: '',
      slug: 'all',
      fetch: this.getProducts,
    },
    {
      name: 'Khuyến mại giá tốt',
      active: false,
      value: '1',
      icon: 'assets/svg/hot.svg',
      slug: 'khuyen-mai-gia-tot',
      fetch: this.getSaleProducts,
    },
    {
      name: 'Giá tốt nhất thị trường',
      active: false,
      value: '1',
      icon: 'assets/svg/hot.svg',
      slug: 'gia-tot-nhat-thi-truong',
      fetch: this.getBestPrice,
    },
    {
      name: 'Sản phẩm độc quyền',
      active: false,
      value: '1',
      icon: 'assets/svg/hot.svg',
      slug: 'san-pham-doc-quyen',
      fetch: this.getExclusiveProducts,
    },
    // {
    //   name: 'Sản phẩm Flash Sale',
    //   active: false,
    //   value: '',
    //   slug: 'san-pham-flash-sale',
    //   fetch: this.getFlashSale,
    // },
    // {
    //   name: 'Sản phẩm điểm',
    //   active: false,
    //   value: '',
    //   slug: 'san-pham-diem',
    //   fetch: this.getSanPhamDiem,
    // },
    // {
    //   name: 'Sản phẩm bán chạy',
    //   active: false,
    //   value: '',
    //   slug: 'san-pham-ban-chay',
    //   fetch: this.getSanPhamBanChay,
    // },
    {
      name: 'Sản phẩm yêu thích',
      active: false,
      value: '',
      slug: 'san-pham-yeu-thich',
      fetch: this.getWishlist,
    },
  ];

  currentTab: string = '';

  public views: number = 1524;

  private searchSubject = new Subject<string>();
  private readonly debounceTimeMs = 500; // Set the debounce time (in milliseconds)

  private changeQueryStringSubcription?: Subscription;
  public searchUserQuery: SearchUserQueryString = new SearchUserQueryString();
  public categoryQuery: CatetoryQueryString = new CatetoryQueryString();
  public labelQuery: LabelQueryString = new LabelQueryString();

  public isAuthenticated: boolean;
  public isAccountActivated: boolean;

  // wishlist
  wishlistIds: number[] = [];
  inWishList: boolean = false;

  // modal
  public title: string = '';
  public imageUrls: string[] = [];
  private routerEvent: Subscription;
  cartItemsMap: Map<number, any> = new Map();

  isBrowser: boolean = false;

  constructor(
    public productService: ProductService,
    private route: ActivatedRoute,
    public router: Router,
    private commonService: CommonService,
    private queryStringService: QueryStringService,
    private flashSaleService: FlashSaleService,
    private wishlistService: WishlistService,
    private toastService: ToastrService,
    private accountService: AccountService,
    private cookieService: SsrCookieService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private transferState: TransferState,
    private authService: AuthService
  ) { }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (isPlatformBrowser(this.platformId)) { // For SSR
      let number = window.screenY || document.documentElement.scrollTop || document.body.scrollTop || 0;
      if (number >= 167) {
        if (window.innerWidth < 800) {
          $('.div-search').removeClass('sticky');
          $('.div-search').addClass('sticky-mobile');
        }
        else {
          $('.div-search').removeClass('sticky-mobile');
          $('.div-search').addClass('sticky');
        }
      } else {
        $('.div-search').removeClass('sticky');
        $('.div-search').removeClass('sticky-mobile');
      }
    }
  }

  ngOnInit() {
    this.isBrowser = isPlatformBrowser(this.platformId);
    this.isAuthenticated = this.authService.isAuthenticated();
    this.isAccountActivated = this.authService.isAccountActivated();
    this.categoryQuery = this.queryStringService.getQueryString(
      new CatetoryQueryString()
    );
    this.changeQueryStringSubcription =
      this.queryStringService.eventChangeQueryString.subscribe(() => {
        this.fetchingDataProcessor();
      });

    this.commonService.getWishlist.subscribe({
      next: (res: number[]) => {
        if (res && res.length > 0) {
          this.wishlistIds = res;
        }
      },
    });

    this.getCart();

    this.route.queryParams.subscribe(async (params) => {
      if (params['s']) {
        this.inputText = params['s'];
        for (let f of this.filters) {
          if (f.slug == 'all') {
            f.active = true;
          } else {
            f.active = false;
          }
        }
      } else {
      }
    });
    this.queryStringService.startFetchingData();
    this.searchSubject
      .pipe(debounceTime(this.debounceTimeMs))
      .subscribe((searchValue) => {
        this.searchUserQuery.value = this.inputText;
        this.queryStringService.changeQueryString(this.searchUserQuery);
      });

    this.routerEvent = this.router.events.subscribe({
      next: (val: any) => {
        if (val instanceof NavigationEnd) {
          if (this.router.url.includes('/quick-order')) {
            if (isPlatformBrowser(this.platformId)) {
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }
          }
        }
      },
    });
  }

  getProducts(pageNumber?: number) {

    if (this.inputText == undefined) {
      this.inputText = '';
    }

    this.bodyExtractSearch.searchTerm = this.inputText.trim();
    this.loaderStatus = true;
    this.itemsExtend = [];

    let req: PaginationModel = {
      searchTerm: this.inputText.trim(),
    } as PaginationModel;

    this.fetchingDataProcessor(req);
  }

  getProductsPagination(pageNumber?: number): Promise<boolean> {
    let body = this.queryStringService.buildFetchingModel();
    this.skeletonLoader = true;
    body.limit = 20;
    this.itemsExtend = [];

    const token = this.transferState.get(TOKEN, '');

    if (token) {
      return new Promise((r) => {
        this.productService.getProductsPaginationForMember(body).subscribe({
          next: (res: ProductsPaginationModel) => {
            if (res) {
              this.itemsExtend = res.products.map((item) => {
                item.cartItem = this.getCartItem(item);
                return item;
              });

              let pagination: PaginationModel = {
                limit: body.limit,
                offset: body.offset,
                searchTerm: body.searchTerm,
                totalItems: res.total,
                sortBy: body.sortBy,
                category: '',
              };
              this.skeletonLoader = false;
              this.queryStringService.changePagination(pagination);
              r(true);
            }
          },
          error: (err: any) => {
            console.error(err);
            r(false);
          }
        })
      })

    }
    else {
      return new Promise(r => {
        this.productService.getProductsPaginationforGuest(body).subscribe({
          next: (res: ProductsPaginationModel) => {
            if (res) {
              this.items = res.products.map((item) => {
                item.cartItem = this.getCartItem(item);
                return item;
              });
              let pagination: PaginationModel = {
                limit: body.limit,
                offset: body.offset,
                searchTerm: body.searchTerm,
                totalItems: res.total,
                sortBy: body.sortBy,
                category: '',
              };
              this.skeletonLoader = false;
              this.queryStringService.changePagination(pagination);
              r(true);
            }
          },
          error: (err: any) => {
            console.error(err);
            r(false);
          }
        })
      })


    }
  }

  input() {
    // if (this.searchUserQuery.value != this.inputText) {
    //   this.skeletonLoader = true;
    //   this.items = [];
    //   this.searchSubject.next(this.inputText);
    // }

    this.skeletonLoader = true;
    this.items = [];
    this.searchSubject.next(this.inputText);
  }

  getCartItem(product: any) {
    let cartItem = {
      imgUrl: product.imageUrls[0],
      name: product.name,
      price: product.basePrice,
      basePrice: product.basePrice,
      productID: product.productID,
      quantity: 0,
      labels: product.labels,
      SKU: product.SKU,
      slug: product.slug,
    };

    const existingCartItem = this.cartItemsMap.get(product.productID);
    if (existingCartItem) {
      cartItem = existingCartItem;
    }

    return cartItem;
  }

  updateCartItem() {
    if (this.items && this.items.length) {
      this.items.forEach((product: Product) => {
        let findCartItem = this.cartItemsMap.get(product.productID);
        if (findCartItem) {
          product.cartItem = findCartItem;
        }
      })
    }
    if (this.itemsExtend && this.itemsExtend.length) {
      this.itemsExtend.forEach((product: Product) => {
        let findCartItem = this.cartItemsMap.get(product.productID);
        if (findCartItem) {
          product.cartItem = findCartItem;
        }
      })
    }
  }

  filter(e: any) {
    this.currentTab = e.slug;
    this.queryStringService.clearAllQueryStrings();
    let selected = this.filters.find((f) => f.active);
    if (selected && e.slug === selected.slug) {
      return;
    }
    this.changedMode = true;
    this.inputText = "";

    for (let f of this.filters) {
      if (f.slug != e.slug) {
        f.active = false;
      } else {
        f.active = true;
      }
    }

    if (typeof e.fetch === 'function') {
      e.fetch.call(this);
    }
  }

  getAllProducts() {
    this.categoryQuery.value = '';
    this.getProducts();
  }

  getFlashSale() {
    this.skeletonLoader = true;
    this.flashSaleService.getFlashSale().subscribe({
      next: (res: any) => {
        if (res.currentPeriod) {
          this.items = res.currentPeriod.products.map((item: any) => {
            item.cartItem = this.getCartItem(item);
            return item;
          });
          let pagination: PaginationModel = {
            totalItems: res.currentPeriod.products.length,
          };
          this.queryStringService.changePagination(pagination);
        }
      },
      error: (error: any) => {
        console.error(error);
      },
      complete: () => {
        this.inputText = '';
        this.skeletonLoader = false;
        this.categoryQuery.value = '';
      },
    });
  }

  getSaleProducts(){
    let req: PaginationModel = {
      labels: ['khuyen-mai-gia-tot'],
    } as PaginationModel;
    this.fetchingDataProcessor(req);
  }

  getBestPrice() {
    let req: PaginationModel = {
      labels: ['gia-tot-nhat-thi-truong'],
    } as PaginationModel;
    // this.labelQuery.value = 'gia-tot-nhat-thi-truong';
    // this.queryStringService.changeQueryString(this.labelQuery);
    this.fetchingDataProcessor(req);
  }

  getExclusiveProducts() {
    let req: PaginationModel = {
      labels: ['san-pham-doc-quyen'],
    } as PaginationModel;
    
    this.fetchingDataProcessor(req);
  }

  getSanPhamDiem() {
    let req: PaginationModel = {
      tags: ['san-pham-diem'],
    } as PaginationModel;

    this.fetchingDataProcessor(req);
  }

  getSanPhamBanChay() {
    let req: PaginationModel = {
      tags: ['san-pham-ban-chay'],
    } as PaginationModel;

    this.fetchingDataProcessor(req);
  }

  getWishlist() {
    let req: PaginationModel;
    if (this.wishlistIds.length) {
      req = {
        productIds: this.wishlistIds,
      } as PaginationModel;
    } else {
      req = {
      } as PaginationModel;
    }
    this.fetchingDataProcessor(req);
  }

  addToWishlist(id: number) {
    const body = {
      productId: id,
    };
    this.wishlistService.addWishlist(JSON.stringify(body)).subscribe({
      next: (res: number[]) => {
        if (res) {
          if (res.some((r) => r == id)) {
            this.toastService.success('Thêm vào danh sách yêu thích!', '', {
              positionClass: 'toast-top-right',
            });
          } else {
            this.toastService.success('Loại bỏ khỏi danh sách yêu thích!', '', {
              positionClass: 'toast-top-right',
            });
          }
          this.commonService.setWishlist(res);
          this.wishlistIds = res;
          if (this.currentTab == 'san-pham-yeu-thich') {
            this.getWishlist();
          }
        }
      },
      error: (error: any) => {
        this.toastService.error('Không thể cập nhật danh sách yêu thích!', '', {
          positionClass: 'toast-top-right',
        });
        console.error(error);
      },
    });
  }

  checkInWishlist(productId: number) {
    let inWishList = false;
    for (let id of this.wishlistIds) {
      if (id == productId) {
        inWishList = true;
        break;
      }
    }
    return inWishList;
  }

  openModal(index: number, item: Product, productDetailImageModal: any) {
    this.title = item.name;
    this.imageUrls = item.imageUrls;
    productDetailImageModal.openModal();
  }
  ngOnDestroy() {
    this.routerEvent?.unsubscribe();
    this.changeQueryStringSubcription?.unsubscribe();
  }

  queryStringBuilder(slug: string) {
    this.productLabel.emit(slug);
    this.router.navigate(['/product'], {
      queryParams: {
        label: slug,
      },
      queryParamsHandling: '',
    });
  }

  createCartItemsMap(cartItems: any[]): Map<number, any> {
    const cartItemsMap = new Map<number, any>();
    for (let item of cartItems) {
      cartItemsMap.set(item.productID, item);
    }
    return cartItemsMap;
  }
  getCart() {
    this.commonService.getCart.subscribe({
      next: (res: any) => {
        if (res) {
          this.cart = res;
          this.cartItemsMap = this.createCartItemsMap(this.cart.items);
          this.updateCartItem();
        }
      },
    });
  }
  private changedMode: boolean = false; // Indicated mode changed to reset body
  private defaultBody: PaginationModel = {
    limit: 20,
    offset: 0,
  } as PaginationModel; // Default body use for change tab
  private body: PaginationModel = {
    ...this.defaultBody,
  }; // Body - current pagination for selected tab

  fetchingDataProcessor(orderBody?: PaginationModel) {
    this.items = [];
    this.itemsExtend = [];
    if (this.changedMode) {
      this.changedMode = false;
      this.body = {
        ...this.defaultBody,
      } as PaginationModel;
    }
    else {
      let body = this.queryStringService.buildFetchingModel();
      this.copyObjectValue(this.body, body);
    }
    if (orderBody) {
      this.copyObjectValue(this.body, orderBody);
    } else {
      this.body.searchTerm = this.inputText;
    }
    this.skeletonLoader = true;

    const token = this.transferState.get(TOKEN, '');
    let selectedFilter = this.filters.find(p => p.active);

    if (token) {
      if (selectedFilter?.slug === 'all') {
        let that = this;
        if (this.body.searchTerm) {
          firstValueFrom(
            this.productService.searchProductsForMember(
              JSON.stringify(this.body)
            )
          ).then(function (res) {
            if (res) {
              that.items = res.products;
              that.body.totalItems = res.total;
              that.queryStringService.changePagination(that.body);
              for (let item of that.items) {
                item.cartItem = that.getCartItem(item);
              }
              if (that.items.length < that.bodyExtractSearch.limit!) {
                that.getProductsPagination().then(function () {
                  that.itemsExtend = that.itemsExtend.filter(
                    (item) => !that.items.some((i) => i.id === item.id)
                  );
                  for (let item of that.itemsExtend) {
                    item.cartItem = that.getCartItem(item);
                  }
                });
              }

              that.skeletonLoader = false;
              that.loaderStatus = false;
            }
          });
        } else {
          that.getProductsPagination();
        }
      } else {
        this.productService.getProductsForMember(JSON.stringify(this.body)).subscribe({
          next: (res: ProductModel) => {
            this.itemsExtend = res.products.map((item) => {
              item.cartItem = this.getCartItem(item);
              return item;
            });
            this.body.totalItems = res.total;
            this.queryStringService.changePagination(this.body);
          },
          error: (error: any) => {
            console.error(error);
          },
          complete: () => {
            this.skeletonLoader = false;
          },
        });
      }
    } else {
      if (selectedFilter?.slug === 'all') {
        let that = this;
        if (this.body.searchTerm) {
          firstValueFrom(
            this.productService.searchProductsForGuest(
              JSON.stringify(this.body)
            )
          ).then(function (res) {
            if (res) {
              that.items = res.products;
              that.body.totalItems = res.total;
              that.queryStringService.changePagination(that.body);
              for (let item of that.items) {
                item.cartItem = that.getCartItem(item);
              }
              if (that.items.length < that.bodyExtractSearch.limit!) {
                that.getProductsPagination().then(function () {
                  that.itemsExtend = that.itemsExtend.filter(
                    (item) => !that.items.some((i) => i.id === item.id)
                  );
                  for (let item of that.itemsExtend) {
                    item.cartItem = that.getCartItem(item);
                  }
                });
              }
              that.skeletonLoader = false;
              that.loaderStatus = false;
            }
          });
        } else {
          that.getProductsPagination();
        }
      } else {
        this.productService.getProductsForGuest(JSON.stringify(this.body)).subscribe({
          next: (res: ProductModel) => {
            this.items = res.products.map((item) => {
              item.cartItem = this.getCartItem(item);
              return item;
            });
            this.body.totalItems = res.total;
            this.queryStringService.changePagination(this.body);
          },
          error: (error: any) => {
            console.error(error);
          },
          complete: () => {
            this.skeletonLoader = false;
          },
        });
      }
    }
  }

  copyObjectValue(target: PaginationModel, source: PaginationModel | RetriveUserModel) {
    target.searchTerm = source.searchTerm;

    if (source.categories && source.categories.length) {
      target.categories = source.categories;
    }
    if (source.category) {
      target.category = source.category;
    }
    if (source.limit) {
      target.limit = source.limit;
    }
    if (source.offset) {
      target.offset = source.offset;
    }
    else {
      target.offset = 0;
    }

    if (source.labels && source.labels.length) {
      target.labels = source.labels;
    }
    if (source.productIds && source.productIds.length) {
      target.productIds = source.productIds;
    }

    if (source.tags && source.tags.length) {
      target.tags = source.tags;
    }
    if (source.sortBy) {
      target.sortBy = source.sortBy;
    }
  }
}
