import { Voucher } from '@/app/shared/interface/voucher.interface';
import { isPlatformBrowser } from '@angular/common';
import { Component, EventEmitter, Inject, Input, Output, PLATFORM_ID, TemplateRef, ViewChild } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { VoucherDetailModalComponent } from './voucher-detail-modal/voucher-detail-modal.component';

@Component({
  selector: 'app-voucher-modal',
  templateUrl: './voucher-modal.component.html',
  styleUrl: './voucher-modal.component.scss'
})
export class VoucherModalComponent {

  @Input() vouchers: Voucher[] = [];

  @ViewChild("voucherModal", { static: false }) voucherModal: TemplateRef<any>;
  @ViewChild("detailModal", { static: false }) detailModal: VoucherDetailModalComponent;

  @Output() confirmed: EventEmitter<boolean> = new EventEmitter();
  @Output() useVoucher: EventEmitter<Voucher> = new EventEmitter();

  public closeResult: string;
  public modalOpen: boolean = false;

  selectedVoucher: Voucher;
  
  constructor(
    private modalService: NgbModal,
    private toastService: ToastrService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  ngOnInit() {
  }

  async openModal() {
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalOpen = true;
      this.modalService.open(this.voucherModal, {
        ariaLabelledBy: 'Voucher-Modal',
        centered: true,
        windowClass: 'theme-modal modal-lg'
      }).result.then((result) => {
        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        this.confirm(false);
      });
    }
  }

  private getDismissReason(reason: ModalDismissReasons): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  confirm(value: boolean) {
    this.confirmed.emit(value);
    this.modalService.dismissAll();
  }

  copyToClipboard(voucher: Voucher) {
    if(isPlatformBrowser(this.platformId)){
      navigator.clipboard.writeText(voucher.code);
    }
    this.toastService.success("Sao chép mã giảm giá thành công!", "" , {positionClass: "toast-top-right"});
  }

  async popupDetail(voucher: any) {
    this.selectedVoucher = voucher;
    this.detailModal.openModal();
  }

  applyVoucher(voucher: Voucher) {
    this.useVoucher.emit(voucher)
    if (this.modalOpen) {
      this.modalService.dismissAll();
    }
  }

  validateVoucherUnitUsage(event: any, voucher: Voucher) {
    let val = event.target.value;

    if (val < 1) {
      event.target.value = 1;
    }

    if (val > voucher.remainingUses) {
      event.target.value = voucher.remainingUses;
    }

    voucher.unitsUsed = Number(event.target.value);
  }

  ngOnDestroy() {
    if (this.modalOpen) {
      this.modalService.dismissAll();
    }
  }
}
