<ng-template #detailModal let-modal>
    <div class="modal-body p-3">
      <div class="d-flex justify-content-center position-relative pt-3">
        <h5 class="modal-title" style="color: #247636">CHI TIẾT MÃ GIẢM GIÁ</h5>
        <!-- <button class="voucher-detail-btn close-btn" (click)="closeModal()">
          <img src="assets/svg/close 1.svg" />
        </button> -->
      </div>
      <img class="ms-auto" src="assets/svg/promo 1.svg" />
      <h3 class="color-red pt-3">
        Giảm
        <span *ngIf="selectedVoucher.discountType == 1; else percent">{{
          selectedVoucher.discountValue | currencySymbol
          }}</span>
        <ng-template #percent>
          {{ selectedVoucher.discountValue }} %
        </ng-template>
      </h3>
      <div class="div-info">
        <div class="d-flex p-3 justify-content-center border-bottom row-info">
          <div class="col-xxl-12 col-lg-12 col-md-12 colsm-12 text-start" style="padding-left: 50px;">
            <ul>
              <li>
                <h4 style="padding: 5px;" *ngIf="selectedVoucher.discountType == 0">
                  Giảm giá tối đa
                  {{ selectedVoucher.maximumAmount| currencySymbol }} cho đơn
                  trị giá tối thiểu từ
                  {{ selectedVoucher.minimumSpentRequired | currencySymbol }}
                </h4>
  
                <h4 style="padding: 5px;" *ngIf="selectedVoucher.discountType == 1">
                  Giảm
                  {{ selectedVoucher.discountValue| currencySymbol }} cho đơn
                  trị giá tối thiểu từ
                  {{ selectedVoucher.minimumSpentRequired | currencySymbol }}
                </h4>
              </li>
              <li *ngIf="selectedVoucher.code">
                <h4 class="position-relative" style=" padding: 5px;">
                  Mã giảm giá: <span class="text-danger fw-bold">{{ selectedVoucher.code }}</span>
                  <img class="position-absolute" style="width: 1rem; bottom: 10px; margin-left: 6px"
                    src="assets/svg/copy 1.svg" alt="Copy voucher" (click)="copyToClipboard(selectedVoucher)" />
                </h4>
              </li>
              <li *ngIf="selectedVoucher.conditions?.end" style=" padding: 5px;">
                <h4>
                  Có hiệu lực tới ngày: <span class="text-danger fw-bold">{{ selectedVoucher.conditions?.end | date :
                    "dd/MM/yyyy" }}</span>
                </h4>
              </li>
              <li *ngIf="selectedVoucher.usesLimitPerDay">
                <h4 style="padding: 5px;">
                  Số lượt sử dụng/ngày: <span class="text-danger fw-bold">{{ selectedVoucher.usesLimitPerDay > 99999 ?
                    'Không giới hạn' : selectedVoucher.usesLimitPerDay }}</span>
                </h4>
              <li>
                <h4 style="padding: 5px;">
                  Số lượt sử dụng còn lại: <span class="text-danger fw-bold">{{ selectedVoucher.remainingUses > 99999 ?
                    'Không giới hạn' : selectedVoucher.remainingUses }}</span>
                </h4>
              </li>
            </ul>
  
            <!-- <div *ngIf="info.type == 4" [innerHTML]="info.value"></div> -->
          </div>
        </div>
      </div>
    </div>
  </ng-template>